import React from "react";
import Card from "../Card/Card";
import { useState } from "react";
import PromotionModal from "../Modal/Modal";

export default function List({ promotions }) {
  const [promotionId, setPromotionId] = useState(null);

  if (promotions.length === 0) {
    return <div>Nenhum resultado encontrado</div>;
  }

  return (
    <div>
      {promotions.map((promotion) => (
        <Card
          promotion={promotion}
          key={promotion.id}
          onClickComments={() => {
            setPromotionId(promotion.id);
          }}
        />
      ))}
      {promotionId && (
          <PromotionModal
            promotionId={promotionId}
            onClickClose={() => setPromotionId(null)}
          />
      )}
    </div>
  );
}
