import List from '../../../components/Promotion/List/List'
import SearchBar from '../../../components/Promotion/SearchBar/SearchBar';
import { Link } from 'react-router-dom'
import React, {useState, useEffect} from 'react'

function PagesPromotionSearch() {

  const [ data, setData] = useState([]);
  const [ params, setParams ] = useState(''); 

  useEffect(() => {
      if(params){
        fetch(`http://localhost:5000/promotions?_embed=comments&title_like=${params}`)
        .then((response) => {
            return response.json()
        })
        .then((apiData) => {
            setData(apiData)
        } )
      }else{
        fetch("http://localhost:5000/promotions?_embed=comments")
            .then((response) => {
                return response.json()
            })
            .then((apiData) => {
                setData(apiData)
            } )

      }
  } , [params])


  function getValues(value){
    setParams(value)
  }

  return (
    <div className="App">
        <h1>PROMOSHOW</h1>
        <Link to="/form">Nova Promoção</Link>
        <SearchBar onChange={getValues}/>
        <List promotions={data} />
    </div>
  );
}

export default PagesPromotionSearch;
