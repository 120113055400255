import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/Form/Form'
import { useParams } from 'react-router-dom'

export default function PagesPromotionForm() {

    const {id} = useParams();

    return (
        <div>
            <Link to="/">Voltar</Link>
            <Form id={id ? Number.parseInt(id,10) : null} />
        </div>
    )
}
