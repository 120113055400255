import React from 'react'
import './SearchBar.css'

export default function SearchBar({onChange}) {
    
    function getValue(event){
        onChange(event.target.value)
    }
    
    return (
        <div className='searchBar'>
            <label htmlFor="searchPromotion">Buscar Promoções</label>
            <input id="searchPromotion" className="search-input" type="text" placeholder='Pesquisar em Promoções' onChange={getValue}/>
        </div>
    )
}
