import React from "react";
import UIModal from "../../UI/Modal/Modal";
import { useEffect, useState } from "react";
import CommentsTree from "./CommentsTree/CommentsTree";
import "./Modal.css";

export default function PromotionModal({ promotionId, onClickClose }) {
  const [comment, setComment] = useState("");
  const [data, setData] = useState();

  function load() {
    fetch(
      `http://localhost:5000/comments?promotionId=${promotionId}&_expand=user`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      });
  }

  useEffect(() => {
    load();
  }, []);

  function getData() {
    let data = {
      userId: 1,
      promotionId,
      comment,
    };
    return JSON.stringify(data);
  }

  async function onSubmit(ev) {
    ev.preventDefault();
    const myData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: getData(data),
    };
    try {
      await fetch(`http://localhost:5000/comments`, myData);
      setComment("");
      load();
    } catch (error) {
      console.log(error);
    }
  }

  async function sendAnswer(text, parentId) {
    let data = {
      userId: 1,
      promotionId,
      comment: text,
      parentId,
    };

    let myData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    await fetch(`http://localhost:5000/comments`, myData);
    load();
  }

  return (
    <UIModal onClickClose={onClickClose} isOpen>
      <form className="promotion-modal__comment-form" onSubmit={onSubmit}>
        <textarea
          onChange={(ev) => {
            setComment(ev.target.value);
          }}
          value={comment}
          placeholder="Comentar..."
          className="promotion-modal__comment-box"
        ></textarea>
        <button type="submit">Enviar</button>
      </form>
      <CommentsTree comments={data} sendComment={sendAnswer} />
    </UIModal>
  );
}
