import React from 'react';
import PagesPromotionForm from './Promotion/Form/Form';
import PagesPromotionSearch from './Promotion/Search/Search';
import { BrowserRouter, Routes, Route} from "react-router-dom";

export default function Router(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PagesPromotionSearch />} />
                <Route path="form" element={<PagesPromotionForm/>} />
                <Route path="/form/:id" element={<PagesPromotionForm />}/>
            </Routes>
        </BrowserRouter>
    )
} 
