import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import "./Form.css";

export default function Form({id}) {
  
  const initialValues = {
    title: "",
    url: "",
    imageUrl: "",
    price: 0,
  };

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(initialValues);

  useEffect( () => {
    if(id){
      fetch(`http://localhost:5000/promotions/${id}`)
        .then( response => {
          return response.json()
        })
        .then( data => {
          setFormValues(data)
        })
    }

  },[id])

  function apiPutData(data,method){

    const myData = {
        method,
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(data)
    }
    if(method === 'PUT'){
      fetch(`http://localhost:5000/promotions/${id}`, myData)
        .then( response => {
          navigate("/")
        }
      )
    }else{
      fetch("http://localhost:5000/promotions/", myData)
        .then( response => { 
          navigate("/")
      })
      
    }

  }

  function getData(event) {
    event.preventDefault();
    if(id){
      apiPutData(formValues,'PUT')
    }else{
      apiPutData(formValues,'POST')
    }
    }

  

  function onChange(event) {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  }

  return (
    <div className="form">
      <form action="#" onSubmit={getData}>
        <label htmlFor="title">Título</label>
        <input
          value={formValues.title}
          name="title"
          onChange={onChange}
          type="text"
          id="title"
          placeholder="Título"
        />

        <label htmlFor="url">Link da Promoção</label>
        <input
          value={formValues.url}
          type="text"
          onChange={onChange}
          name="url"
          id="url"
          placeholder="Url"
        />

        <label htmlFor="imageUrl">Link da Imagem</label>
        <input
          value={formValues.imageUrl}
          type="text"
          onChange={onChange}
          name="imageUrl"
          id="imageUrl"
          placeholder="Link da imagem"
        />

        <label htmlFor="price">Preço R$</label>
        <input
          value={formValues.price}
          type="number"
          onChange={onChange}
          name="price"
          step="0.01"
          id="price"
        />

        <button type="submit">Enviar</button>
      </form>
    </div>
  );

}