import React from "react";
import "./Card.css";
import { Link } from 'react-router-dom'

export default function Card({ promotion, onClickComments }) {
  return (
    <div className="card">
      <div className="card-img">
        <img src={promotion.imageUrl} alt={promotion.title} />
      </div>
      <div className="card-txt">
        <h2>{promotion.title}</h2>
        <p className="card-txt__price">R$ {promotion.price}</p>
        {promotion.comments.length >= 1 ? <p className="card-txt__comment">{promotion.comments[0].comment}</p> : <p className="card-txt__comment"></p>}
        <div className="card-links">
          <button onClick={onClickComments}>
            {promotion.comments.length}{" "}
            {promotion.comments.length === 1 ? "Comentário" : "Comentários"}
          </button>
          <div className="card-edit">
            <a className="card-link" href={promotion.url} target="_blank" rel="noreferrer">
              Ir para o site
            </a>
            <Link className="card-edit__component" to={`/form/${promotion.id}`} >Editar promoção</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
